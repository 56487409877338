<template>
  <div>
    <div class="pa-3 flex-container d-flex flex-column">
      <v-container>
        <v-row>
          <v-col
            class="pt-0" 
            cols="12" 
            sm="8" offset-sm="2"
            md="6" offset-md="3"
          >
            <div class="d-flex justify-center">
              <v-img v-if="this.$store.state.theme === 'payagent'"
                max-width="250"
                class="mt-5 ml-5"
                src="../../assets/PayAgentBranding/undraw_transfer_money.svg"
                contain
              />
              <v-img v-else
                max-width="250"
                class="mt-5 ml-5"
                src="../../assets/CalmonyPayBranding/undraw_transfer_money.svg"
                contain
              />
            </div>

            <div class="mt-8 grey--text text--darken-1">
              <p class="text-body-1 text-sm-h5 mb-5 text-center">Was your bank not listed?</p>

              <!-- Conditionally render if payment details stored in $store -->
              <BankDetails v-if="$store.state.paymentDetails" />

              <ContactDetails v-if="$store.state.paymentDetails" />
              <p v-else class="text-center">Please contact your agent.</p>
            </div>
            
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import BankDetails from '@/components/BankDetails.vue';
import ContactDetails from '@/components/ContactDetails.vue';

export default {    
  name: 'bankNotShown',
  components: {
    BankDetails,
    ContactDetails
  }
}
</script>

<style scoped>

</style>
